.dash-content {
    margin-top: 10px;
    margin-left: 10px;
    background-color: #fff;
    padding: 5px 5px;
}
.dash-title {
    display: inline-flex;
    text-transform: uppercase;
    text-align: left;
    font-size: 14px;
    color: #333333;
    font-family: "Arial Bold", "Arial";
    font-weight: 700;
    font-style: normal;
    padding-bottom: 5px;
    border-bottom: solid #aaaaaa 2px;
    margin-bottom: 5px;
}
.grid-item {
    padding: 5px 10px;
}
.siderbar-selected {
    background-color: #333333;
    font-weight: bold;
}

.sub-menu-header-bar {
    text-decoration: none;
    color: inherit;
}
.button-date-picker {
    position: absolute;
    right: -5px;
}

.button-group > *,
.button-group > button {
    margin: 5px;
}

.button-group-center {
    display: flex;
    justify-content: center;
}

.button-group-center > *,
.button-group-center > button {
    margin: 8px;
}

.label-checkbox {
    color: #7f7f7f;
    font-size: 14px;
    font-weight: 700;
}

.MuiInputBase-input.Mui-disabled {
    background-color: #f0f0f0;
    color: #333333;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.dzu-input {
    display: none;
}

.label-info {
    font-size: 14px !important;
    font-weight: 700 !important;
    text-align: right;
}

.text-info {
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1;
    display: block;
}

.url-link:hover {
    font-style: italic;
}

.search_map {
    z-index: 99;
    position: fixed;
    top: 20vh;
    left: 35vh;
}

.dialog_map {
    width: 40vh;
}

.footer_map {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    flex-flow: row;
}

@media only screen and (max-width: 767px) {
    .router_mobile {
        display: none;
    }
    .search_map {
        left: 0vh;
        top: 0vh;
    }
    .dialog_map {
        width: 89vw;
    }
    .button_mobile {
        width: 100%;
        margin: 0px !important;
        margin-bottom: 4px !important;
    }
    .footer_map {
        padding-top: 0;
    }
}
